<template>
  <main>
    <form>
      <h1 class="pt-10 pb-16">
        Réservation
      </h1>

      <h2 class="pb-5" > Statut de la réservation - {{ booking.status }}</h2>
      <v-select
        v-model="booking.status"
        :items="bookingStatues"
        item-text="label"
        item-value="id"
        label="Choisissez le nouveau statut de la réservation"
        @change="getNewStatus(booking.status)"
      ></v-select>
      
      <br />

      <v-checkbox
      v-model="booking.refunded"
      label= "L'opération est-elle remboursée ?"
    ></v-checkbox>

      <h2 class="pb-5">Commentaire</h2>
      <v-textarea
        v-model="comment"
        label="Votre commentaire"
        auto-grow
        outlined
        @change="getNewComment()"
      >
      </v-textarea>

      <br />

      <v-btn class="info" @click="submit()">
        Valider
      </v-btn>

    </form>   
    


  </main>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    BaseUrl: process.env.VUE_APP_BASEURL,
    booking: {},
    bookingStatues: [],
    newStatus:Number,
    comment:"",
  }),

  mounted() {
    axios
      .get(
        process.env.VUE_APP_APIURL +
          "/bookings/" +
          this.$route.params.bookingUid,
        {
          headers: {
            "Access-Token": localStorage.getItem("token"),
          },
         params : {
							expand : "application"
					}
        }
      )
      .then((res) => {
        this.booking = res.data;
        const status = [
           "1-Demandé 🕒", 
           "2-Payé ✅", 
           "3-Refusé ❌",
           "4-Annulé",
           "5-Accepté",
        ]
        this.bookingStatues = status;
        this.comment = this.booking.comments ? this.booking.comments : ''
      });

  },

  methods: {

    getNewStatus(){
      this.newStatus = parseInt(this.booking.status.charAt(0));
    },

    getNewComment(){
      this.booking.comments = this.comment;
    },

    submit() {

      this.booking.status = this.newStatus != this.booking.status ? this.newStatus : this.booking.status

      axios.put(
          process.env.VUE_APP_APIURL + "/bookings/" + this.$route.params.bookingUid,
          {
            status: this.booking.status ? this.booking.status : undefined,
            comments: this.booking.comments ? this.booking.comments : null,
            refunded: this.booking.refunded,
          },
          {
            headers: {
              "Access-Token": localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            document.location.reload();
          }
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
  },
};
</script>

<style lang="scss">
main {
  h1{
    text-align: center;
  }
  margin: 10rem 30% ;
}
</style>
